import React, { useEffect, useReducer, useState } from "react";
import dayjs from "dayjs";
import axios from "../../../axios/axios";

import PageHeader from "../../../components/PageHeader";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DirectionsIcon from "@mui/icons-material/Directions";

import ColorizeIcon from "@mui/icons-material/Colorize";
import { InputAdornment, Box, Paper, TableContainer } from "@mui/material";

import DataGrid from "../../../components/useDataGrid";
import Popup from "../../../components/Popup";
import Controls from "../../../components/Controls";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Notification from "../../../components/Notification";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// import { Stack } from "@mui/system";
import { MyUseForm, MyForm } from "../../../components/useForm";
// import LabReportPrint from "./LabReportPrint";

import * as GenServices from "../../../helper/GenServices";
import * as BillingServices from "../../../helper/BillingServices";
import { useSelector, useDispatch } from "react-redux";
import { addLabTestListMasterData } from "../../../redux/actions";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import Billing_Report_Print from "./Billing_Report_Print";
import Billing_Summarywise_Report from "./Billing_Summarywise_Report";
import Billing_Headwise_Report from "./Billing_Headwise_Report";

export default function BillingReport() {
  const [loading, setLoading] = useState(false);
  const [consultant, setConsultant] = useState([]);

  // const [total, setTotal] = useState({
  //   newOPD: 0,
  //   oldOPD: 0,
  //   totalOPD: 0,
  //   totalValue: "",
  // });

  //   const dispatch = useDispatch();
  // const AllsubHeadings = useSelector((state) => state.subHeadingList_Reducer.mainHeading);

  //   const hook_AllTestList = useSelector((state) => state.LabTestList_Reducer.testData);

  // const records = useSelector((state)=> state.LabTestList_Reducer.testData);
  //console.log("records==>  ", records);

  // const [formData, setFormData] = useReducer((state, newState)=>( ({...state, ...newState}), initialValues ));
  //   const [allLabTestList, setAllLabTestList] = useState([]);

  const [hid] = useState(2);

  // const [records, setRecords] = useState([]);
  // const [originalReportData, setOriginalReportData] = useState([]);

  const [reportData, setReportData] = useState([]);

  // const [searchVal, setSearchVal] = useState("");

  const [totalValue, setTotalValue] = useState(0);
  const [totalCashAmt, setTotalCashAmt] = useState(0);
  const [totalOtherAmt, setTotalOtherAmt] = useState(0);

  const [reportType, setReportType] = useState("SUMMARY");

  const [mainFeeHeadList, setMainFeeHeadList] = useState([]); //<== These heads taken from FeeHeadList Collection

  const [subFeeHeadList, setsubFeeHeadList] = useState([]); //<== These heads taken from FeeHeadList Collection

  const [appointmentTypeValue, setAppointmentTypeValue] = useState([]);

  // const [formData, setFormData] = useState({
  //   startdate: dayjs(),
  //   enddate: dayjs(),
  // });

  const [formData, setFormData] = useState({
    consultant: "ALL",
    startdate: dayjs(),
    enddate: dayjs(),
    feeHeadMain: { val: "ALL", text: "ALL" },
    feeHead: { val: "ALL", text: "ALL" },
    reportType: "SUMMARY",
    appointmentType: "ALL",
  });

  // const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });

  const [isPrintDirect, setIsPrintDirect] = React.useState(false);

  const [openPopupReceipt, setOpenPopupReceipt] = React.useState(false);

  const handlePrintDetailReport = () => {
    setIsPrintDirect(true);
    // setPrintForm(res.data);
    setOpenPopupReceipt(true);
  };

  //   const { formData, setFormData, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);
  // const { errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getAppointmentType = async () => {
    const rrr = await GenServices.getEntityInnnerData(2, "AppointmentType");
    // console.log("AppointmentType ------", rrr);
    let R1 = await rrr.map((item) => {
      return { val: item.val, text: item.text };
    });
    R1.unshift({ val: "ALL", text: "ALL" });
    setAppointmentTypeValue(R1);
    // return rrr;
  };

  const getMainFeeHeadList = async () => {
    let options = [];
    try {
      options = await BillingServices.getMainHeadingList(hid);
      options.unshift({ val: "ALL", text: "ALL" });
      // options.push({ val: "ALL", text: "ALL" });
      // options = [...options, options2]
      // console.log("BillingServices.getMainFeeHeadList ========>", options);
      setMainFeeHeadList(options);
    } catch (error) {
      console.error("getMainFeeHeadList===>", error.message);
      setMainFeeHeadList(options);
    }
  };

  const getSubFeeHeadList = async () => {
    let options = [];
    try {
      options = await BillingServices.getSubHeadingListWithHeadName(hid);
      options.unshift({ val: "ALL", text: "ALL" });
      // options.push({ val: "ALL", text: "ALL" });
      // options = [...options, options2]
      // console.log("BillingServices.getSubHeadingList ========>", options);
      setsubFeeHeadList(options);
    } catch (error) {
      console.error("getSubFeeHeadList===>", error.message);
      setsubFeeHeadList(options);
    }
  };

  const getConsultants = async () => {
    const rrr = await GenServices.getConsultants(hid);
    // console.log("getConsultants ------", rrr);
    const data = rrr.map((item) => ({ val: item.text, text: item.text }));
    // data.push({ val: "ALL", text: "ALL" });
    data.unshift({ val: "ALL", text: "ALL" });
    // console.log("getConsultants data ------", data);

    setConsultant(data);
    // return rrr;
  };

  useEffect(() => {
    getAppointmentType();
    getConsultants();
    getSubFeeHeadList();
    getMainFeeHeadList();
  }, []);

  const getReport = async () => {
    //setLoading(true);
    // validate(initialValues);
    try {  
      const res = await axios.get(
        `/fee/opdbill/getbillingreport/?hid=${hid}&reporttype=${formData.reportType}&feeheadmain=${formData.feeHeadMain.val}&feehead=${formData.feeHead.val}&appointmenttype=${formData.appointmentType}&consultant=${formData.consultant}&startdate=${formData.startdate}&enddate=${formData.enddate}`
      );
      
      // console.log("getReport AAAAAA ===>", res.data);

      if (res) {
        // setOriginalReportData(res.data);

        // setSearchVal("");
        // let totalPaidRs = res.data.reduce((acc=0, obj) => {
        //   console.log(obj.paidRs)
        //   return acc += parseFloat(obj.paidRs);
        // });
        // let totalRs = res.data.reduce((sum, row) => sum + parseFloat(row.paidRs), 0);
        let totalRs = 0;
        let totalCashRs = 0;
        let totalOtherRs = 0;
        if (formData.reportType === "SUMMARY" || formData.reportType === "SUMMARY-2") {
          totalRs = res.data.reduce((sum, row) => sum + row.paidRs, 0);
          // totalCashRs = res.data.reduce((sum, row) => row.paymentMode == "Cash" ? sum + row.paidRs : 0 , 0);
          totalCashRs = res.data.reduce((sum, row) => row.paymentMode === "Cash" ? sum + row.paidRs : sum, 0);
          totalOtherRs = res.data.reduce((sum, row) => row.paymentMode != "Cash" ? sum + row.paidRs : sum , 0);

          // console.log("res.data 111111111111111111111 ====>", res.data);
          setReportData(res.data);
        } else if (formData.reportType === "HEADWISE") {
          totalRs = res.data.reduce((sum, row) => sum + row.netAmt, 0);
          totalCashRs = res.data.reduce((sum, row) => row.paymentMode === "Cash" ? sum + row.netAmt : sum , 0);
          totalOtherRs = res.data.reduce((sum, row) => row.paymentMode != "Cash" ? sum + row.netAmt : sum , 0);
          const data2 = res.data.map((row) => ({ ...row, paidRs: row.netAmt }));
          setReportData(data2);
        }

        setTotalValue("₹" + totalRs);
        setTotalCashAmt("₹" + totalCashRs);
        setTotalOtherAmt("₹" + totalOtherRs);

        // console.log("setOriginalReportData ===>", res.data);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  //   const getHeadwiseReport = async () => {
  //     //setLoading(true);
  //     //validate(initialValues);
  //     try {
  //       const res = await axios.get(
  //         `/fee/opdbill/getfeecollectionreportheadwise/?hid=${hid}&feehead=${formData.feeHead.val}&consultant=${formData.consultant}&startdate=${formData.startdate}&enddate=${formData.enddate}`
  //       );
  //       if (res) {
  //         setOriginalReportData(res.data);
  //         setReportData(res.data);
  //         setSearchVal("");

  //         // let totalPaidRs = res.data.reduce((acc=0, obj) => {
  //         //   console.log(obj.paidRs)
  //         //   return acc += parseFloat(obj.paidRs);
  //         // });
  //         // let totalRs = res.data.reduce((sum, row) => sum + parseFloat(row.paidRs), 0);
  //         let totalRs = res.data.reduce((sum, row) => sum + row.netAmt, 0);

  //         setTotalValue(totalRs);

  //         // console.log("setOriginalReportData ===>", res.data);
  //       }
  //       // setLoading(false);
  //     } catch (error) {
  //       // setLoading(false);
  //       setNotify({ isOpen: true, message: error.message, type: "error" });
  //     }
  //     //setLoading(false);
  //   };

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    getReport();
    // console.log("formData=", formData);
  }, [formData]);

  // useEffect(() => {
  //   console.log("getReport  ===>", reportData);
  // }, [reportData]);

  //   const getRecords = () => {
  //     if (formData.reportType === "SUMMARY") {
  //       getReport();
  //     } else if (formData.reportType === "HEADWISE") {
  //       getHeadwiseReport();
  //     }
  //   };

  //Below data for Grid-Detail table
  // mrNo, opdNo, name, gender, age, billNo, billDate, paidRs, paymentMode, paymentDetail, consultant
  const columnSummary = [
    { field: "billNo", headerName: "BillNo", width: 100 },
    {
      field: "billDate",
      headerName: "BillDate",
      width: 150,
      valueGetter: (item) => dayjs(item.row.billDate).format("DD/MM/YYYY HH:mm"),
    },
    { field: "mrNo", headerName: "MRNo", width: 100 },
    { field: "opdNo", headerName: "OPDNo", width: 100 },
    // { field: "visitNo", headerName: "VisitNo", width: 100 },
    { field: "name", headerName: "Name", width: 200 },
    // { field: "soDo", headerName: "So/Do", width: 80 },
    // { field: "fhName", headerName: "F/H Name", width: 220 },
    {
      field: "gender",
      headerName: "Gender/Age",
      width: 120,
      valueGetter: (item) => item.row.gender + "/ " + item.row.age,
    },
    // { field: "pMobNo", headerName: "MobNo", width: 120 },
    // { field: "email", headerName: "Email", width: 200 },

    // { field: "validDays", headerName: "Valid-Days", width: 100 },
    // { field: "validUpto", headerName: "Valid-Upto", width: 100 },

    // { field: "appointmentType", headerName: "Appoint-Type", width: 130 },
    // { field: "depttName", headerName: "DepttName", width: 150 },
    // { field: "depttAnnualNo", headerName: "DepttAnnualNo", width: 100 },
    { field: "consultant", headerName: "Consultant", width: 200 },
    { field: "appointmentType", headerName: "AppointmentType", width: 150 },
    // { field: "unitName", headerName: "UnitName", width: 100 },

    // { field: "paidRs", headerName: "PaidRs", width: 100 },
    {
      field: "paidRs",
      headerName: "Cash Amt.",
      width: 100,
      valueGetter: (item) => {return item.row.paymentMode === "Cash" ? item.row.paidRs: ""},
    },
    {
      field: "paidRs1",
      headerName: "Other-Amt.",
      width: 100,
      valueGetter: (item) => {return item.row.paymentMode !="Cash" ? item.row.paidRs: ""},
    },
    { field: "paymentMode", headerName: "PaymentMode", width: 120 },
    { field: "paymentDetail", headerName: "PaymentDetail", width: 120 },
  ];

  // mrNo, opdNo, name, gender, age, billNo, billDate, paidRs, paymentMode, paymentDetail, consultant
  const columnDetail = [
    { field: "billNo", headerName: "BillNo", width: 100 },
    {
      field: "billDate",
      headerName: "BillDate",
      width: 150,
      valueGetter: (item) => dayjs(item.row.billDate).format("DD/MM/YYYY HH:mm"),
    },
    { field: "mrNo", headerName: "MRNo", width: 100 },
    { field: "opdNo", headerName: "OPDNo", width: 100 },
    // { field: "visitNo", headerName: "VisitNo", width: 100 },
    { field: "name", headerName: "Name", width: 200 },
    // { field: "soDo", headerName: "So/Do", width: 80 },
    // { field: "fhName", headerName: "F/H Name", width: 220 },
    {
      field: "gender",
      headerName: "Gender/Age",
      width: 120,
      valueGetter: (item) => item.row.gender + "/ " + item.row.age,
    },
    // { field: "pMobNo", headerName: "MobNo", width: 120 },
    // { field: "email", headerName: "Email", width: 200 },

    // { field: "validDays", headerName: "Valid-Days", width: 100 },
    // { field: "validUpto", headerName: "Valid-Upto", width: 100 },

    // { field: "appointmentType", headerName: "Appoint-Type", width: 130 },
    // { field: "depttName", headerName: "DepttName", width: 150 },
    // { field: "depttAnnualNo", headerName: "DepttAnnualNo", width: 100 },
    { field: "consultant", headerName: "Consultant", width: 200 },
    { field: "appointmentType", headerName: "AppointmentType", width: 150 },
    // { field: "unitName", headerName: "UnitName", width: 100 },

    { field: "paymentMode", headerName: "PaymentMode", width: 120 },
    //feeHeadMain,feeHead,rate,qty,amount,discAmt,netAmt
    { field: "feeHeadMain", headerName: "FeeHead-Main", width: 200 },
    { field: "feeHead", headerName: "Fee-Head", width: 300 },
    { field: "rate", headerName: "Rate", width: 100 },
    { field: "qty", headerName: "Qty", width: 100 },
    { field: "amount", headerName: "Amount", width: 100 },
    { field: "discAmt", headerName: "Disc-Amt", width: 100 },
    { field: "netAmt", headerName: "Net-Amt", width: 100 },
    {
      field: "paidRs",
      headerName: "Cash Amt.",
      width: 100,
      valueGetter: (item) => {return item.row.paymentMode === "Cash" ? item.row.paidRs: ""},
    },
    {
      field: "paidRs1",
      headerName: "Other-Amt.",
      width: 100,
      valueGetter: (item) => {return item.row.paymentMode !="Cash" ? item.row.paidRs: ""},
    },
    // { field: "paymentDetail", headerName: "PaymentDetail", width: 120 },
  ];

  // const handleSearch = async (e) => {
  //   const val = e.target.value;
  //   // const str = val || "";
  //   // console.log("BindBottomTable Value========>", val);
  //   setSearchVal(val);
  //   //if (val) {
  //   const filteredRows = search(originalReportData, val);
  //   // console.log("filteredRows =>", filteredRows);
  //   setReportData(filteredRows);
  //   //}
  // };

  // const [fieldNamesForSearch] = useState(["name", "fhName", "mrNo", "opdNo", "pMobNo", "email"]);

  // function search(allItems, val) {
  //   return allItems.filter((item) => {
  //     return fieldNamesForSearch.some((newItem) => {
  //       return item[newItem].toString().toLowerCase().indexOf(val.toLowerCase()) > -1;
  //     });
  //   });
  // }

  // const cancelSearch = () => {
  //   setSearchVal("");
  //   setReportData(originalReportData);
  // };

  return (
    <div>
      <PageHeader title="Billing Report" icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />} />

      {/* <Paper> */}
      {/* <MyForm onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={0} sm={0} md={2} lg={2}></Grid>
        {/* billDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="Start-Date"
            name="startDate"
            value={dayjs(formData.startdate).format("DD/MMM/YYYY")}
            onChange={(e) => setFormData({ ...formData, startdate: e.target.value })}
            // error={errors.billDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>

        {/* sampleDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="End-Date"
            name="endDate"
            value={dayjs(formData.enddate).format("DD/MMM/YYYY")}
            onChange={(e) => setFormData({ ...formData, enddate: e.target.value })}
            // error={errors.sampleDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Controls.DropdownList
            label="Report Type"
            name="reportType"
            value={formData.reportType}
            onChange={(e) => {
              setFormData((preValue) => ({ ...preValue, reportType: e.target.value }));
              setFormData((preValue) => ({ ...preValue, feeHead: { val: "ALL", text: "ALL" } }));
            }}
            options={[
              { val: "SUMMARY", text: "SUMMARY" },
              { val: "SUMMARY-2", text: "SUMMARY-2" },
              { val: "HEADWISE", text: "HEADWISE" },
            ]}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2}>
          <Controls.DropdownList
            label="AppointmentType"
            name="appointmentType"
            value={formData.appointmentType}
            onChange={(e) => setFormData({ ...formData, appointmentType: e.target.value })}
            options={appointmentTypeValue}
          />
        </Grid>

        {/* <Grid item xs={0} sm={0} md={2} lg={2}></Grid> */}

        <Grid item xs={0} sm={0} md={2} lg={2}></Grid>

        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Controls.DropdownList
            label="Consultant"
            name="consultant"
            value={formData.consultant}
            onChange={(e) => setFormData({ ...formData, consultant: e.target.value })}
            options={consultant}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Controls.Autocomplete
            label="Fee Head Main"
            name="feeHeadMain"
            // value={(subFeeHeadList.find((element) => element.ref_fee_head_nested_id == formData.ref_fee_head_nested_id).text)}
            value={formData.feeHeadMain.text}
            // onChange={handleChange_FeeSubHeading}
            // onChange={(e) => handleChange(e.target.value.val)}
            onChange={(e) => {
              if (e.target.value) {
                setFormData({ ...formData, feeHeadMain: e.target.value });
              }
            }}
            options={mainFeeHeadList}
            // error={errors.feeHead}
            // title={"FeeHeadSub mapping with Test-Sub-Heading"}
          />
        </Grid>

        {formData.reportType === "HEADWISE" && (
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Controls.Autocomplete
              label="Fee Head Sub"
              name="feeHead"
              // value={(subFeeHeadList.find((element) => element.ref_fee_head_nested_id == formData.ref_fee_head_nested_id).text)}
              value={formData.feeHead.text}
              // onChange={handleChange_FeeSubHeading}
              // onChange={(e) => handleChange(e.target.value.val)}
              onChange={(e) => {
                if (e.target.value) {
                  setFormData({ ...formData, feeHead: e.target.value });
                }
              }}
              options={subFeeHeadList}
              // error={errors.feeHead}
              // title={"FeeHeadSub mapping with Test-Sub-Heading"}
            />
          </Grid>
        )}

        <Grid item xs={2} sm={2} md={2} lg={1}>
          <Controls.Button variant="outlined" text="Go" onClick={() => getReport()} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <div style={{ textAlign: "center" }}>Total Amount Rs.:- {totalValue}</div> */}

          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              <Chip sx={{ fontSize: "15px" }} label="Total Amount Rs.:-" />
              <Chip sx={{ fontSize: "20px" }} label={totalValue} color="success" />
              {/* <Controls.Button variant="outlined" text="Go to Print" onClick={() => alert("Goooooooood")} /> */}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

        {/* Below Tables for Billing_Summarywise_Report */}
      {formData.reportType === "SUMMARY" || formData.reportType === "SUMMARY-2" ? (
        <Box sx={{ mb: "50px", mt: "20px" }}>
          <Billing_Summarywise_Report
            totalValue={totalValue}
            reportData={reportData}
            formData={formData}
            isPrintDirect={isPrintDirect}
            setIsPrintDirect={setIsPrintDirect}
          />
        </Box>
      ) : (   
        // Below Tables for Billing_Headwise_Report
        <Box sx={{ mb: "50px", mt: "20px" }}>
          <Billing_Headwise_Report
            totalValue={totalValue}
            reportData={reportData}
            formData={formData}
            isPrintDirect={isPrintDirect}
            setIsPrintDirect={setIsPrintDirect}
          />
        </Box>
      )}

      <div style={{ position: "relative", left: "80%" }}>
        <Controls.ActionButton color="primary" title="Print Report" onClick={handlePrintDetailReport}>
          <PrintIcon />
        </Controls.ActionButton>
      </div>

      {/* Below Tables for GridView_Report */}
      <Box sx={{ mb: "50px", mt: "20px" }}>
        <DataGrid
          // heading="OPD Report"
          columns={formData.reportType === "SUMMARY" || formData.reportType === "SUMMARY-2" ? columnSummary : columnDetail}
          rows={reportData}
          showToolbar={true}
          pageSize={20}
          autoPageSize
          boxHeight={400}
        />
      </Box>

      {/* Actual Printable Report */}
      <Popup
        title="Billing Detail Report"
        openPopup={openPopupReceipt}
        setOpenPopup={setOpenPopupReceipt}
        isCloseOutside={true}
      >
        <Billing_Report_Print
          totalCashAmt={totalCashAmt}
          totalOtherAmt={totalOtherAmt}
          reportData={reportData}
          formData={formData}
          isPrintDirect={isPrintDirect}
          setIsPrintDirect={setIsPrintDirect}
        />
      </Popup>
      <br></br>
      <br></br>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
