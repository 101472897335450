import React, { useEffect, useRef, useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import Controls from "../../../components/Controls";
import dayjs from "dayjs";

import * as GenServices from "../../../helper/GenServices";

import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { useSelector, useDispatch } from "react-redux";
import TableForPrintBill from "./TableForPrintBill";

export default function Billing_Summarywise_Report(props) {
  // const dispatch = useDispatch();
  const hospitalHeader = useSelector((state) => state.HospitalHeader_Reducer);
  // console.log("hospitalHeader ====>", hospitalHeader);
  // const {printForm} = props;

  const { isPrintDirect, setIsPrintDirect } = props;

  const reportData = props.reportData;
  const formData = props.formData;
  const totalValue = props.totalValue;
  // const reportType = props.reportType;

  const [arrTableData, setArrTableData] = useState([]);

  const [headerPrint, setHeaderPrint] = useState(false);

  //console.log("formData ====>", formData);

  const createGroup = async () => {
    let dataList = [];

    //   const data = await [...reportData].sort((a, b) => a["feeHeadMain"] - b["feeHeadMain"]); //.reverse();

    const groupByfeeHeadMain = await GenServices.groupBy(reportData, "feeHeadMain");

    // console.log("groupByCategory aaaaaaaaaaaa ====>", groupByfeeHeadMain);

    //   const ddd = groupByfeeHeadMain.split();

    //Below code remove the Key eg. {'OT-Charges': [obj1, obj2], 'FeeHead': [objFeeHead1, objFeeHead2]}
    //and convert to [[objOT1, objOT2], [objFeeHead1, objFeeHead2]]
    const ddd = Object.keys(groupByfeeHeadMain).map((key) => groupByfeeHeadMain[key]);

    // console.log("ddd aaaaaaaaaaaaaaaa ====>", ddd);

    if (formData.reportType === "SUMMARY") {
      ddd.map(async (curRow) => {
        // dataList;
        // console.log("row ====>", row);

        const { feeHeadMain } = curRow[0];

        const groupBy = await curRow.reduce((acc, row) => {
          // console.log("row ==>", row);
          // debugger;

          const item = acc.length > 0 && acc.find(({ consultant }) => consultant === row.consultant);
          //&& acc.find(({ paidRs }) => paidRs === row.paidRs);

          if (item) {
            item.NoOfBills += 1;
            // item.amtRs = row.paidRs; <= this is already exists so do not required update again
            item.paidRs += row.paidRs;
          } else {
            acc.push({ consultant: row.consultant, NoOfBills: 1, amtRs: row.paidRs, paidRs: row.paidRs });
          }
          return acc;
        }, []);

        dataList.push({ feeHeadMain, groupBy });
      });

      setArrTableData(dataList);
      //console.log("dataList ====>", dataList);
    } else if (formData.reportType === "SUMMARY-2") {
      ddd.map(async (curRow) => {
        // dataList;
        // console.log("row ====>", row);

        const { feeHeadMain } = curRow[0];

        const groupBy = await curRow.reduce((acc, row) => {
          // console.log("row ==>", row);
          // debugger;

          const item =
            acc.length > 0 &&
            acc.find(({ consultant, amtRs }) => consultant === row.consultant && amtRs === row.paidRs);
          //&& acc.find(({ paidRs }) => paidRs === row.paidRs);

          if (item) {
            item.NoOfBills += 1;
            // item.amtRs = row.paidRs; <= this is already exists so do not required update again
            item.paidRs += row.paidRs;
          } else {
            acc.push({ consultant: row.consultant, NoOfBills: 1, amtRs: row.paidRs, paidRs: row.paidRs });
          }
          return acc;
        }, []);

        dataList.push({ feeHeadMain, groupBy });
      });

      setArrTableData(dataList);
      //console.log("dataList ====>", dataList);
    }
  };

  let marginTop = "50px";
  let marginBottom = "50px";

  let marginLeft = "10px";
  let marginRight = "10px";

  const getPageMargins = () => {
    return `.showHeader{ display: none;}   
          @media print{ @page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;} 
          .showHeader{ display: block !important; } }`;
  };

  useEffect(() => {
    createGroup();
  }, [reportData]);

  //   useEffect(() => {
  //     console.log("arrTableData ====>", arrTableData);
  //   }, [arrTableData]);

  const handleClose = (value) => {
    setIsPrintDirect && setIsPrintDirect(false);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // const getPageMargins = () => {
  //   return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  // };

  useEffect(() => {
    // isPrintDirect && handlePrint();
    handleClose();
    // TableBodyRow();
  }, [isPrintDirect]);

  let tableHeaderValue = "";
  let isPrintTableHeader = true;

  return (
    // <h1>{props.opdNo}</h1>
    <>
      <style>{getPageMargins()}</style>

      <div style={{ position: "relative", left: "80%" }}>
        <Controls.ActionButton
          color="primary"
          title="Print Report"
          onClick={(e) => {
            handlePrint();
            setHeaderPrint(true);
          }}
        >
          <PrintIcon />
        </Controls.ActionButton>
      </div>
      <div ref={componentRef}>
        {/* <header className="labReportHeader"> */}

        {/* {headerPrint && ( */}

        <header className="showHeader">
          {/* This is Header Part */}
          <div
            style={{
              width: "1060px",
              border: "0",
              backgroundColor: "transparent",
              // display: "flex",
              // height:"400pt",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                width: "960px",
                backgroundColor: "White",
                border: "0",
                marginLeft: "auto",
                marginRight: "auto",
                fontWeight: "bold",
              }}
            >
              <div>
                <table className="tbl1111" style={{ width: "100%", border: "1px solid black" }}>
                  {/* <thead></thead> */}
                  <tbody>
                    <tr>
                      <td style={{ width: "25%" }}>
                        <div style={{ padding: "10px", textAlign: "right" }}>
                          <img src="logo192.png" alt="Header Image" width={110} height={80} />
                        </div>
                      </td>
                      <td style={{ width: "50%", textAlign: "center" }}>
                        <div>
                          <span style={{ fontSize: "22px" }}> {hospitalHeader.hospitalName} </span>
                        </div>
                        <div> {hospitalHeader.hospitalAddress} </div>
                        <div> {hospitalHeader.hospitalAddress2} </div>
                        {/* <div> {hospitalHeader.hospitalPhoneNo} </div> */}
                      </td>
                      <td style={{ width: "25%" }}> </td>
                    </tr>
                  </tbody>
                  {/* <tfoot></tfoot> */}
                </table>
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    textAlign: "center",
                    // textUnderlineOffset: "5px",
                    // textDecorationLine: "underline",
                    paddingTop: "10px",
                  }}
                >
                  Doctorwise Billing Report
                </div>

                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <table style={{ width: "100%", border: "1px solid black" }}>
                    <tr>
                      <td className="center" style={{ width: "50%" }}>
                        From: {dayjs(formData.startdate).format("DD/MMM/YYYY")} To:
                        {dayjs(formData.enddate).format("DD/MMM/YYYY")}
                      </td>
                      <td className="center" style={{ width: "50%" }}>
                        Consultant: {formData.consultant}{" "}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </header>

        <table
          id="labMainTable1111"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <thead>
            <tr>
              <td>
                <div style={{ backgroundColor: "transparent" }} className="lab-header-dumy-space1111"></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {/* <section className="labReportBody"> */}
                <div>
                  <div
                    style={{
                      width: "1060px",
                      border: "0",
                      backgroundColor: "transparent",
                      // display: "flex",
                      // height:"400pt",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div
                      style={{
                        width: "960px",
                        backgroundColor: "White",
                        border: "0",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div
                        style={{
                          border: "2px solid #000000",
                          // borderRadius: "7px",
                          padding: "5px",
                          marginTop: "5px",
                          backgroundColor: "#f0f0f0",
                        }}
                      >
                        <table style={{ width: "100%" }}>
                          {/* <thead></thead> */}
                          <tbody>
                            <tr style={{ fontWeight: "bold" }}>
                              <td style={{ width: "50%", textAlign: "center" }}> Main Fee-Head/Consultant </td>
                              <td style={{ width: "25%", textAlign: "center" }}> No. of Bills </td>
                              <td style={{ width: "25%", textAlign: "center" }}> Bill Charges</td>
                            </tr>
                          </tbody>
                          {/* <tfoot></tfoot> */}
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* </header> */}
                </div>

                {/* Report Body Content */}
                <div
                  style={{
                    width: "960px",
                    backgroundColor: "white",
                    border: "0",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div style={{ padding: "5px", border: "1px solid black" }}>
                    {/* {<TableBodyRow />} */}

                    {Array.isArray(arrTableData) &&
                      arrTableData.map((row, index) => {
                        return <TableForPrintBill {...row} key={index} reportType={formData.reportType} />;
                      })}

                    <table style={{ width: "100%", fontWeight: "bold", fontSize: "20px" }}>
                      <tr>
                        {/* <td>{index + 1}</td> */}
                        <td style={{ textAlign: "right", width: "50%", fontSize: "14px" }}>Grand Total</td>
                        <td style={{ textAlign: "center", width: "25%", backgroundColor: "silver" }}>
                          {reportData.length} Bills
                        </td>
                        <td style={{ textAlign: "center", width: "25%", backgroundColor: "silver" }}>{totalValue}</td>
                      </tr>
                    </table>
                  </div>
                  {/* <div style={{ margin: "30px", textAlign: "center" }}>
                    * * * * * * * * * * * * * * * * End of Report * * * * * * * * * * * * * * * *
                  </div> */}
                </div>
                {/* </div> */}

                {/* <div style={{ marginTop: "50px" }}></div> */}
                {/* </section> */}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className="lab-footer-dumy-space">&nbsp;</div>
              </td>
            </tr>
          </tfoot>
        </table>

        {/* <div className="lab-footer">
          <div style={{ textAlign: "right", marginRight: "50px", fontSize: "11px" }}>
            <span style={{ fontWeight: "normal" }}> MRNo.: </span>
            <span style={{ fontWeight: "normal" }}> Name: </span>
          </div> 
        </div> */}

        {/* <div className="lab-footer">Lab Incharge</div> */}

        {/* <div class="footer">    
    <div align="center">Page <span class="pagenumber"/> of <span class="pagecount"/></div>
    <div> Lab Incharge  </div> </div> */}
      </div>
    </>
  );
}
