import React, { useEffect, useReducer, useState, useRef } from "react";
import dayjs from "dayjs";
import axios from "../../../axios/axios";
import { useReactToPrint } from "react-to-print";

import PageHeader from "../../../components/PageHeader";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DirectionsIcon from "@mui/icons-material/Directions";

import ColorizeIcon from "@mui/icons-material/Colorize";
import { InputAdornment, Box, Paper, TableContainer } from "@mui/material";

import DataGrid from "../../../components/useDataGrid";
import Popup from "../../../components/Popup";
import Controls from "../../../components/Controls";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Notification from "../../../components/Notification";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import OPD_Deptwise_Print from "./OPD_Deptwise_Print";
import OPD_List_Print from "./OPD_Report_Print";

// import { Stack } from "@mui/system";
import { MyUseForm, MyForm } from "../../../components/useForm";
// import LabReportPrint from "./LabReportPrint";

import * as GenServices from "../../../helper/GenServices";
import { useSelector, useDispatch } from "react-redux";
import { addLabTestListMasterData } from "../../../redux/actions";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

export default function OPD_Report() {  
  
  const [loading, setLoading] = useState(false);
  const [consultant, setConsultant] = useState([]);

  //   const dispatch = useDispatch();
  // const AllsubHeadings = useSelector((state) => state.subHeadingList_Reducer.mainHeading);

  //   const hook_AllTestList = useSelector((state) => state.LabTestList_Reducer.testData);

  // const records = useSelector((state)=> state.LabTestList_Reducer.testData);
  //console.log("records==>  ", records);

  // const [formData, setFormData] = useReducer((state, newState)=>( ({...state, ...newState}), initialValues ));
  //   const [allLabTestList, setAllLabTestList] = useState([]);

  const [hid] = useState(2);

  // const [records, setRecords] = useState([]);
  const [originalReportData, setOriginalReportData] = useState([]);
  const [reportData, setReportData] = useState([]);

  const [isPrintDirect, setIsPrintDirect] = React.useState(false);

  const [openPopupReceipt, setOpenPopupReceipt] = React.useState(false);

  // const [searchVal, setSearchVal] = useState("");

  // const [totalValue, setTotalValue] = useState(0);

  const [total, setTotal] = useState({
    newOPD: 0,
    oldOPD: 0,
    totalOPD: 0,
    totalValue: "",
  });

  const [totalCashAmt, setTotalCashAmt] = useState(0);
  const [totalOtherAmt, setTotalOtherAmt] = useState(0);

  // const [formData, setFormData] = useState({
  //   startdate: dayjs(),
  //   enddate: dayjs(),
  // });

  const [formData, setFormData] = useState({
    consultant: "ALL",
    startdate: dayjs(),
    enddate: dayjs(),
    reportType: "SUMMARY",
  });

  // const [recordForEdit, setRecordForEdit] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });

  //   const { formData, setFormData, errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);
  // const { errors, setErrors, handleChange } = MyUseForm(initialValues, true, validate);

  const getConsultants = async () => {
    const rrr = await GenServices.getConsultants(hid);
    console.log("getConsultants ------", rrr);
    const data = rrr.map((item) => ({ val: item.text, text: item.text }));
    data.push({ val: "ALL", text: "ALL" });
    // console.log("getConsultants data ------", data);

    setConsultant(data);
    // return rrr;
  };

  useEffect(() => {
    getConsultants();
  }, []);

  const getReport = async () => {
    //setLoading(true);
    // validate(initialValues);

    try {
      //Below report also used in create OPD page
      const res = await axios.get(
        `/opd/report/?hid=${hid}&consultant=${formData.consultant}&startdate=${formData.startdate}&enddate=${formData.enddate}`
      );
      //console.log("getReport  ===>", res.data);

      if (res) {
        setOriginalReportData(res.data);
        setReportData(res.data);
        // setSearchVal("");

        // let totalPaidRs = res.data.reduce((acc=0, obj) => {
        //   console.log(obj.paidRs)
        //   return acc += parseFloat(obj.paidRs);
        // });
        // let totalRs = res.data.reduce((sum, row) => sum + parseFloat(row.paidRs), 0);
        let totalRs = res.data.reduce((sum, row) => sum + row.paidRs, 0);
        let totalCashRs = res.data.reduce((sum, row) => row.paymentMode === "Cash" ? sum + row.paidRs : sum, 0);
        let totalOtherRs = res.data.reduce((sum, row) => row.paymentMode != "Cash" ? sum + row.paidRs : sum , 0);

        let newOPD = res.data.filter((row) => row.visitNo == "1st").length;
        let oldOPD = res.data.filter((row) => row.visitNo != "1st").length;
        let totalOPD = res.data.length;

        // setTotalValue(totalRs);
        setTotal((preValue) => ({ ...preValue, newOPD: newOPD }));
        setTotal((preValue) => ({ ...preValue, oldOPD: oldOPD }));
        setTotal((preValue) => ({ ...preValue, totalOPD: totalOPD }));
        setTotal((preValue) => ({ ...preValue, totalValue: "₹" + totalRs }));

        setTotalCashAmt("₹" + totalCashRs);
        setTotalOtherAmt("₹" + totalOtherRs);

        // console.log("setOriginalReportData ===>", res.data);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      setNotify({ isOpen: true, message: error.message, type: "error" });
    }
    //setLoading(false);
  };

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    // console.log("formData=", formData);
    getReport();
  }, [formData]);

  //_id, mrNo, opdNo, visitNo, opdDate, validDays, validUpto, patientType, depttName, depttAnnualNo,consultant, unitName,
  //   billNo, billDate:opdDate, paidRs,
  //   name,soDo, fhName, gender, age, pMobNo, email, pAddress
  const columnsLabReport = [
    { field: "mrNo", headerName: "MRNo", width: 70 },
    { field: "opdNo", headerName: "OPDNo", width: 70 },
    // { field: "visitNo", headerName: "VisitNo", width: 100 },
    {
      field: "opdDate",
      headerName: "OPD-Date",
      width: 100,
      valueGetter: (item) => dayjs(item.row.opdDate).format("DD/MM/YYYY hh:mm a"),
    },
    { field: "name", headerName: "Name", width: 200 },
    // { field: "soDo", headerName: "So/Do", width: 80 },
    // { field: "fhName", headerName: "F/H Name", width: 220 },
    {
      field: "gender",
      headerName: "Age/Sex",
      width: 100,
      valueGetter: (item) => item.row.age + "/" + item.row.gender.slice(0, 1),
    },
    // { field: "pMobNo", headerName: "MobNo", width: 120 },
    // { field: "email", headerName: "Email", width: 200 },

    // { field: "validDays", headerName: "Valid-Days", width: 100 },
    // { field: "validUpto", headerName: "Valid-Upto", width: 100 },
    { field: "appointmentType", headerName: "Appoint-Type", width: 110 },
    // { field: "depttName", headerName: "DepttName", width: 150 },
    // { field: "depttAnnualNo", headerName: "DepttAnnualNo", width: 100 },
    { field: "consultant", headerName: "Consultant", width: 180 },
    // { field: "unitName", headerName: "UnitName", width: 100 },
    { field: "billNo", headerName: "BillNo", width: 100 },
    {
      field: "billDate",
      headerName: "BillDate",
      width: 120,
      valueGetter: (item) => dayjs(item.row.billDate).format("DD/MM/YYYY"),
    },
    // { field: "paidRs", headerName: "PaidRs", width: 80 },
    {
      field: "paidRs",
      headerName: "Cash Amt.",
      width: 100,
      valueGetter: (item) => {return item.row.paymentMode === "Cash" ? item.row.paidRs: ""},
    },
    {
      field: "paidRs1",
      headerName: "Other-Amt.",
      width: 100,
      valueGetter: (item) => {return item.row.paymentMode !="Cash" ? item.row.paidRs: ""},
    },
    { field: "visitNo", headerName: "VisitNo", width: 80 },
  ];

  const handlePrintDetailReport = () => {
    setIsPrintDirect(true);
    // setPrintForm(res.data);
    setOpenPopupReceipt(true);
  };

  return (
    <div>
      <PageHeader title="OPD Report" icon={<PeopleOutlineRoundedIcon fontSize="large" color="primary" />} />

      {/* <Paper> */}
      {/* <MyForm onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={0} sm={0} md={1} lg={1}></Grid>
        {/* billDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="Start-Date"
            name="startDate"
            value={dayjs(formData.startdate).format("DD/MMM/YYYY")}
            onChange={(e) => setFormData({ ...formData, startdate: e.target.value })}
            // error={errors.billDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>

        {/* sampleDate */}
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Controls.DatePicker
            label="End-Date"
            name="endDate"
            value={dayjs(formData.enddate).format("DD/MMM/YYYY")}
            onChange={(e) => setFormData({ ...formData, enddate: e.target.value })}
            // error={errors.sampleDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}>
          <Controls.DropdownList
            label="Report Type"
            name="reportType"
            value={formData.reportType}
            onChange={(e) => {
              setFormData((preValue) => ({ ...preValue, reportType: e.target.value }));
            }}
            options={[
              { val: "SUMMARY", text: "SUMMARY" },
              { val: "SUMMARY-2", text: "SUMMARY-2" },
            ]}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Controls.DropdownList
            label="Consultant"
            name="consultant"
            value={formData.consultant}
            onChange={(e) => setFormData({ ...formData, consultant: e.target.value })}
            options={consultant}
            // error={errors.sampleDate}
            // style={{ background: "#e9e9e9" }}
          />
        </Grid>
        {/* sampleDate */}
        <Grid item xs={2} sm={2} md={2} lg={1}>
          <Controls.Button variant="outlined" text="Go" onClick={() => getReport()} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <div style={{ textAlign: "center" }}>Total Amount Rs.:- {totalValue}</div> */}

          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              <Chip sx={{ fontSize: "15px" }} label="New OPDs:-" />
              <Chip sx={{ fontSize: "20px" }} label={total.newOPD} color="success" />

              <Chip sx={{ fontSize: "15px" }} label="Old OPDs:-" />
              <Chip sx={{ fontSize: "20px" }} label={total.oldOPD} color="success" />

              <Chip sx={{ fontSize: "15px" }} label="Total OPDs.:-" />
              <Chip sx={{ fontSize: "20px" }} label={total.totalOPD} color="success" />

              <Chip sx={{ fontSize: "15px" }} label="Total Amount Rs.:-" />
              <Chip sx={{ fontSize: "20px" }} label={total.totalValue} color="success" />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {/* </Paper> */}
      <Box sx={{ mb: "50px", mt: "20px" }}>
        <OPD_Deptwise_Print
          total={total}
          reportData={reportData}
          formData={formData}
          isPrintDirect={isPrintDirect}
          setIsPrintDirect={setIsPrintDirect}
        />
      </Box>

      <div style={{ position: "relative", left: "80%" }}>
        <Controls.ActionButton color="primary" title="Print Report" onClick={handlePrintDetailReport}>
          <PrintIcon />
        </Controls.ActionButton>
      </div>

      <Box sx={{ mb: "50px", mt: "20px" }}>
        <DataGrid
          // heading="OPD Report"
          columns={columnsLabReport}
          rows={reportData}
          showToolbar={true}
          pageSize={20}
          autoPageSize
          boxHeight={400}
        />
      </Box>
      <Popup
        title="OPD Detail Report"        
        openPopup={openPopupReceipt}
        setOpenPopup={setOpenPopupReceipt}
        isCloseOutside={true}
      >
        <OPD_List_Print 
          total={total}
          totalCashAmt={totalCashAmt}
          totalOtherAmt={totalOtherAmt}
          reportData={reportData}
          formData={formData}
          isPrintDirect={isPrintDirect}
          setIsPrintDirect={setIsPrintDirect}
        />
      </Popup>

      <br></br>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
